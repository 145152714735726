<template>
  <div class="tenants-edit mt-4 ml-8 mr-8">
    <v-container fluid v-if="tenant">
      <v-row>
        <v-col>
          <h1 class="display-1 mt-3">
            {{ tenant.name }}
          </h1>
        </v-col>
        <v-col cols="auto">
          <tooltip-button
            icon="history"
            text="History"
            color="default"
            :dark="false"
            @click="$refs.entityLogDialog.open = true"
            position="top"
            size="small"
          />
          <tooltip-button
            icon="supervisor_account"
            text="Benutzer anzeigen"
            color="default"
            :dark="false"
            @click="showUsers()"
            classNames="ml-3"
            position="top"
            size="small"
          />
          <tooltip-button
            icon="save"
            text="Speichern"
            :color="canBeSaved ? 'primary' : 'default'"
            :dark="false"
            @click="save()"
            classNames="ml-3"
            position="top"
            size="medium"
            :disabled="!canBeSaved"
          />
        </v-col>
      </v-row>
      <v-form v-model="valid" @submit.prevent="save()">
        <v-row>
          <v-col cols="6">
            <v-card outlined>
              <v-card-title class="card-title-grey">
                Übersicht
              </v-card-title>
              <v-card-text>
                <v-text-field
                  dense
                  outlined
                  v-model="tenant.name"
                  label="Name"
                  type="text"
                  :maxlength="255"
                  :rules="textRules"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  v-model="tenant.email"
                  label="E-Mail"
                  type="text"
                  :maxlength="255"
                  :rules="emailRules"
                  placeholder=" "
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  v-model="tenant.short_name"
                  label="Kürzel"
                  type="text"
                  readonly
                  disabled
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card outlined>
              <v-card-title class="card-title-grey">
                HR-Verantwortliche
              </v-card-title>
              <v-card-text>
                <template v-if="tenant.hr_managers.length > 0">
                  <div v-for="manager in tenant.hr_managers" :key="manager.id">
                    <h3 class="black--text">
                      {{ manager.first_name }} {{ manager.last_name }}
                    </h3>
                    <p>{{ manager.email }}</p>
                  </div>
                </template>
                <v-alert v-else color="info" dense dark outlined>Keine</v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-tabs
              v-model="tab"
              background-color="grey lighten-3"
              color="grey darken-3"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">
                <v-icon class="mr-2">house</v-icon>
                Hauptadresse
              </v-tab>
              <v-tab href="#tab-2">
                <v-icon class="mr-2">account_balance</v-icon>
                Rechnungsadresse
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-card-text>
                    <address-form
                      :id="
                        tenant.primary_address
                          ? tenant.primary_address.id
                          : null
                      "
                      :type="configs.address_types.primary"
                      :entity="tenant"
                      :entity-name="'tenant'"
                      @created="
                        init = false;
                        fetchTenant();
                      "
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-card-text>
                    <address-form
                      :id="
                        tenant.billing_address
                          ? tenant.billing_address.id
                          : null
                      "
                      :type="configs.address_types.billing"
                      :entity="tenant"
                      :entity-name="'tenant'"
                      @created="
                        init = false;
                        fetchTenant();
                      "
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <entity-log-dialog
      v-if="tenant"
      entity-name="tenants"
      :entity-id="tenant.id"
      ref="entityLogDialog"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import EntityLogDialog from "@/components/dialogs/EntityLogDialog.vue";
import AddressForm from "@/components/forms/AddressForm.vue";
import TooltipButton from "@/components/forms/TooltipButton.vue";

export default {
  components: {
    AddressForm,
    TooltipButton,
    EntityLogDialog
  },

  data: () => ({
    tab: null,
    tenant: null,
    valid: false,
    dirty: false,
    init: false,
    textRules: [v => !!v || "Muss ausgefüllt sein"],
    emailRules: [
      v =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "Ungültige E-Mail."
    ]
  }),

  computed: {
    ...mapState("app", ["configs"]),

    tenantId() {
      return this.$route.params.id;
    },

    canBeSaved() {
      return this.valid && this.dirty && this.tenant;
    }
  },

  watch: {
    tenant: {
      handler(value) {
        if (this.init) {
          this.dirty = true;
        }
        this.init = true;
      },
      deep: true
    }
  },

  created() {
    this.fetchTenant();
  },

  methods: {
    /**
     * Fetch tenant from server
     */
    fetchTenant() {
      this.$api.http
        .get(`backoffice/tenants/${this.tenantId}`)
        .then(response => {
          this.tenant = response.data;
        });
    },

    /**
     * Save tenant.
     */
    save() {
      this.$api.http
        .put(`backoffice/tenants/${this.tenant.id}`, this.tenant)
        .then(response => {
          this.tenant.short_name = response.data.short_name;
          this.$toast.success("Der Mandant wurde aktualisiert.");
          this.$nextTick(() => {
            this.dirty = false;
          });
        })
        .catch(error => {
          this.$toast.error("Der Mandant konnte nicht gespeichert werden.");
        });
    },

    /**
     * Show users
     */
    showUsers() {
      this.$router.push(`/users/?_tenant=${this.tenant.id}`);
    }
  }
};
</script>

<style lang="scss">
.tenants-edit {
  .show-users {
    top: -10px;
  }

  .card-title-grey {
    background-color: #eee;
    padding: 10px 15px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>
